import * as React from "react"
import { Link } from "gatsby"
import { v4 as uuidv4 } from "uuid"
import {
  ForCard,
  FooterRecCard,
  IndustriesStatic,
} from "../components/resources"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Dar from "@mui/icons-material/DoubleArrow"
const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/for-it-ops/",
    label: "For IT OPS",
  },
]

const forItOps = [
  {
    title: "SYSTEMS VISIBILITY",
    LinkUr: "/the-platform/",
    rec: "../images/for-it-ops/svIcon.png",
    content:
      "Our CyberDNA® Command and Control Center provides your IT team with real-time intel on endpoint functionality, network usage and data transportation.",
  },
  {
    title: "BANDWIDTH MANAGEMENT",
    LinkUr: "/bandwidth/case-study",
    rec: "../images/for-it-ops/bmLogo.png",
    content:
      "Full visibility into bandwidth slowdowns, patterns and location sourcing ensures quick remediation and unfettered operational continuity.",
  },
  {
    title: "OPERATIONAL SECURITY",
    LinkUr: "/blog-pages/unexpected-problems",
    rec: "../images/for-it-ops/osLogo.png",
    content:
      "Systems accounting include the monitoring of all communicating technologies. It is vital to keep your production processes and your intellectual property secure.",
  },
]

const resourcesList = [
  {
    title: "VigilantMDR ",
    subtitle: "Managed Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "Great Security ",
    subtitle: "Solves Unexpected Problems",
    LinkUr: "/blog-pages/unexpected-problems/",
    cat: "BLOG",
  },
  {
    title: "Fast Remediation",
    subtitle: " ",
    LinkUr: "/blog-pages/remediation/",
    cat: "BLOG",
  },
]

const ForITOPS = () => (
  <Layout>
    <Seo title="For IT OPS" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
    <section className="section-title-top grid--bg --for-it-ops-2">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h1>IT OPS</h1>
            <h4>
              ADVANCED SECURITY PROVIDES
              <br />
              UNEXPECTED OPERATIONAL BENEFITS
            </h4>
            <p className="content">
              The best cybersecurity features omnidirectional visibility to
              ensure operational efficiency to IT, in the form of time savings
              and expedient problem resolution.
            </p>
            <Link to="/contact/" className="nav-section--cta-btn">
              <div className="d-flex align-items-center">
                <span
                  style={{ marginLeft: "25px" }}
                  className="nav-section--cta-title pr-5"
                >
                  learn how
                </span>
                <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
              </div>{" "}
            </Link>
          </div>
          <div className="section-col-2"></div>
        </div>
      </div>
    </section>
    <section className="section-cards-3--for-card">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <ul className="card-feature-list">
              {forItOps.map(data => {
                return (
                  <ForCard
                    altTxt={data.title}
                    title={data.title}
                    imgSrc={data.rec}
                    content={data.content}
                    LinkUrl={data.LinkUr}
                    key={uuidv4()}
                  />
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div>
      <div className="grid--bg --for-it-ops-2 ">
        <section className="section-col-2-img">
          <div className="container">
            <div className="row">
              <div className="section-col-1">
                <h2>PROVIDING IT OPS EFFICIENCIES </h2>
                <h6 className="text-uppercase">
                  A Superior Security Partner Secures the Higher Ground for Your
                  Entire Company
                </h6>
                <p>
                  Today, your IT Ops team is responsible for your company’s
                  entire software/hardware functionality (including SaaS, cloud
                  and BYOD components.) Vigilant’s 24/7/265 endpoint, network,
                  vulnerability and communications monitoring provides
                  irreplaceable intel for IT expediency.
                </p>
                <Link to="/mdr-services/" className="nav-section--cta-btn">
                  <div className="d-flex align-items-center">
                    <span
                      style={{ marginLeft: "25px" }}
                      className="nav-section--cta-title pr-5"
                    >
                      learn more
                    </span>
                    <Dar
                      className="chevy"
                      sx={{ fontSize: 15, marginLeft: 2 }}
                    />
                  </div>{" "}
                </Link>
              </div>
              <div className="section-col-2"></div>
            </div>
          </div>
        </section>

        <section className="section-platform">
          <div className="container">
            <div className="row">
              <div className="section-col-1">
                <div className="side-binder">
                  <h6>CASE STUDIES</h6>
                  <h3>
                    Real-Time Problem Solving from Your Multi-Dimensional
                    Visibility Partner
                  </h3>
                </div>

                <p className="content">
                  IT Ops staff have a difficult job – made even more challenging
                  when they are unable to see their entire system, internally
                  and externally. With the peerless visibility that Vigilant
                  provides, problems that would otherwise take weeks, can be
                  solved in just hours.
                </p>
              </div>
              <div className="col-sm-6 offset-sm-1"></div>
            </div>
          </div>
        </section>
      </div>
      <section className="section-col-4">
        <div className="container">
          <div className="row">
            <div className="section-col-4--card">
              <h6>COMPLIANCE</h6>
              <p>
                A defense contractor could not afford the time and significant
                cost of new CMMC compliance requirements. Vigilant’s MDR service
                was installed and compliance was quickly attested at a fraction
                of the cost.{" "}
              </p>
            </div>
            <div className="section-col-4--card">
              <h6>FAST REMEDIATION</h6>
              <p>
                A multi-site manufacturing company was experiencing a crippling
                system slowdown every day. They didn’t know it was in a legacy
                system that everyone had forgotten. Vigilant found it in less
                than a day.{" "}
              </p>
            </div>
            <div className="section-col-4--card">
              <h6>BANDWIDTH SUPPORT</h6>
              <p>
                A hospital system was experiencing bandwidth problems caused by
                malware that had infiltrated a disguised, outdated operating
                system. Vigilant’s MDR service bridged the gap between their IT
                and Security teams.{" "}
              </p>
            </div>
            <div className="section-col-4--card">
              <h6>
                FULL VISIBILITY <br />
                PROBLEM SOLVING
              </h6>
              <p>
                A Vigilant client experienced a major systems drag. Because we
                had both deep internal and external visibility, Vigilant’s
                collaborative approach was able to discover a maintenance
                mistake in the external ISP.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="grid--bg --for-it-ops-3">
        <section className="section-title-copy">
          <div className="container">
            <div className="row">
              <div className="section-col-3">
                <hr />
                <h3>INTEGRATED IT SOLUTIONS</h3>
                <h6>COLLABORATIVE VISIBILITY</h6>
                <p className="p-quote text-uppercase">
                  <em>“Victorious Fighters win first and then go to war.”</em>
                </p>
                <p className="p-quote--author">- The Art of War</p>
                <p>
                  Vigilant’s Team walks alongside existing staff, like IT Ops,
                  to collaboratively defend, support and bring peace of mind to
                  the entire organization. Through our fully interactive
                  real-time CyberDNA® Command and Control Center, IT Ops alerts
                  to any suspicious activity. Similarly, Vigilant’s powerful
                  Network (MNDR), Endpoint (MEDR), Vulnerability (MVDR) and
                  Communications (V365) visibility become a part of your IT Ops
                  toolbox.
                </p>
                <Link to="/contact/" className="nav-section--cta-btn">
                  <div className="d-flex align-items-center">
                    <span
                      style={{ marginLeft: "25px" }}
                      className="nav-section--cta-title pr-5"
                    >
                      schedule a call
                    </span>
                    <Dar
                      className="chevy"
                      sx={{ fontSize: 15, marginLeft: 2 }}
                    />
                  </div>
                </Link>
              </div>
              <div className="col-sm-6"></div>
            </div>
          </div>
        </section>
      </div>
      <section className="section-blocks-6">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <div className="side-binder">
                <h6>INDUSTRIES</h6>
                <h3>IT OPS</h3>
              </div>
            </div>
            <div className="section-col-2">
              <IndustriesStatic />
            </div>
          </div>
        </div>
      </section>
    </div>
    <div>
      <section className="section-client-quote">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <div className="section-col-1--content">
                <h3>
                  "Vigilant solved our impossible bandwidth problem in less than
                  a day. Without their remediation, we would have fallen behind
                  in operations and put the future of our business in jeopardy."{" "}
                </h3>
                <hr />
                <h6>- Manufacturing Client</h6>
                <p className="pBlue">
                  (For security reasons, Vigilant never reveals the identity of
                  our clients)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-resources">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h3>RESOURCES</h3>
            </div>
            <div className="section-col-2">
              <ul className="resources-list">
                {resourcesList.map(data => {
                  return (
                    <FooterRecCard
                      altTxt={data.title}
                      title={data.title}
                      subTitle={data.subtitle}
                      recCat={data.cat}
                      LinkUrl={data.LinkUr}
                      key={uuidv4()}
                    />
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default ForITOPS
